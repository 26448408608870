.delete-enter {
    opacity: 1;
    transform: scale(0.9);
}

.delete-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 400ms;
}

.delete-exit {
    opacity: 1;
}

.delete-exit-active {
    opacity: 0;
    transform: scale(0.3);
    transition: opacity 300ms, transform 400ms;
}
