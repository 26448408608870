.fade-in-text {
    opacity: 0;
    animation: fade-in .6s ease-in forwards;
    animation-delay: 0.7s;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
