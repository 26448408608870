.acceptbutton{
    background: white;
    color: black;

}.acceptbutton:hover{
     background: lightgreen;
     color: black;

 }

 .animka{
     transform: scale(1);
     border-radius: 5px;
 }.animka:hover{
       transform: scale(1.2);

   }